const user = {
  state : {
    username     : '',
    account      : '',
    merchantCode : ''
  },

  mutations : {
    // 設定User Info
    setUserInfo (state, payload) {
      state.username = payload.username
      state.account = payload.account
      state.merchantCode = payload.merchantCode
    }
  }
}

export default user
