import { userInfo } from '@/api/global/login'
import { useStore } from 'vuex'

export const routeMiddleware = async (to, from, next) => {
  const store = useStore()

  let result = await userInfo()

  let data = {
    username     : result.data.name,
    account      : result.data.account,
    merchantCode : result.data.merchantCode
  }

  store.commit('setUserInfo', data)

  next()
}
